import { render, staticRenderFns } from "./DigitalRow.vue?vue&type=template&id=044cf480&scoped=true"
import script from "./DigitalRow.vue?vue&type=script&lang=js"
export * from "./DigitalRow.vue?vue&type=script&lang=js"
import style0 from "./DigitalRow.vue?vue&type=style&index=0&id=044cf480&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "044cf480",
  null
  
)

export default component.exports