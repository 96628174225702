<template>
    <sort-list
        v-model="list"
        @init="init"
        :defaultQuery="defaultQuery"
        ref="list"
        :url="`${type == 'COMPANY' ? '/company/all' : '/digital/all'}`"
        :filters="filters"
    >
        <template v-for="item in list">
            <!-- <policy-row :key="item.id" :info="item"></policy-row> -->
            <digital-row :key="item.id" :info="item"></digital-row>
        </template>
    </sort-list>
</template>

<script>
import DigitalRow from '../../components/list/DigitalRow.vue';
import SortList from '../../components/page/SortList.vue';
import { digitalTypeOptions } from '../../utils/variables';
export default {
    components: { SortList, DigitalRow },
    created() {
        this.init();
    },
    data() {
        return {
            settingIdOptions: [],
            fieldOptions: [],
            list: [],
            defaultQuery: {}
        };
    },

    metaInfo() {
        return {
            title: this.typeName
        };
    },
    computed: {
        type() {
            return this.$route.query.type;
        },
        fieldOptionsIndex() {
            return [...this.fieldOptions].filter((item, index) => {
                return index < 20;
            });
        },
        typeName() {
            if (this.$route.query.type) {
                return digitalTypeOptions.find(item => {
                    return item.value === this.$route.query.type;
                })?.label;
            } else {
                return '';
            }
        },
        filters() {
            let type = 'TECHNOLOGY';
            if (this.$route.query.type) {
                type = this.$route.query.type;
            }
            if (type !== 'COMPANY') {
                return [
                    {
                        name: `${this.typeName}区分`,
                        key: 'settingId',
                        type: 'select',
                        list: [...this.settingIdOptions],
                        default: this.$route.query.settingId ? Number(this.$route.query.settingId) : ''
                    },
                    {
                        name: '行业领域',
                        key: 'field',
                        type: 'select',
                        list: [...this.fieldOptionsIndex]
                    },
                    {
                        name: (type == 'TECHNOLOGY' ? '技术' : '产品') + '名称',
                        key: 'name'
                    },
                    {
                        name: (type == 'TECHNOLOGY' ? '技术' : '产品') + '所属人',
                        key: 'owner'
                    }
                    // {
                    //     name: '发布年份',
                    //     key: 'implementationAt',
                    //     type: 'date'
                    // }
                ];
            } else {
                return [
                    {
                        name: '企业性质',
                        key: 'settingId',
                        type: 'select',
                        list: [...this.settingIdOptions],
                        default: this.$route.query.settingId ? Number(this.$route.query.settingId) : ''
                    },
                    {
                        name: '行业领域',
                        key: 'field',
                        type: 'select',
                        list: [...this.fieldOptions]
                    },
                    {
                        name: '所属地区',
                        type: 'selectArea',
                        key: 'owner'
                    },
                    {
                        name: ' 企业名称',
                        key: 'name'
                    }
                ];
            }
        }
    },
    methods: {
        init() {
            let flag = 10;
            if (this.$route.query.type == 'PRODUCT') {
                flag = 12;
            } else if (this.$route.query.type == 'COMPANY') {
                flag = 11;
            }

            Promise.all([
                this.$http.post('/setting/byFlag', { flag: flag }),
                this.$http.post('/setting/byFlag', { flag: 1 })
            ]).then(res => {
                res.forEach((item, index) => {
                    if (index === 0) {
                        this.settingIdOptions = item.map(child => {
                            return {
                                label: child.name,
                                value: child.id
                            };
                        });
                    } else if (index === 1) {
                        this.fieldOptions = item.map(child => {
                            return {
                                label: child.name,
                                value: child.id
                            };
                        });
                    }
                });

                this.$nextTick(() => {
                    this.$refs.list.init();
                });
            });
        }
    }
};
</script>

<style lang="less" scoped></style>
