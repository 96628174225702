<template>
    <router-link
        :to="{
            name: `${type == 'COMPANY' ? 'digitalDetails' : 'digitalDetail'}`,
            query: {
                id: info.id
            }
        }"
        class="patent"
    >
        <el-image :src="getImg(info.img)" fit="cover"></el-image>

        <div class="content">
            <div class="title">{{ info.name }}</div>
            <!-- <div class="flex1" style="flex-gorw: 1"><div class="desc" v-html="info.manual"></div></div> -->
            <div class="time">
                <span>{{ ownerName }}：{{ info.owner }}</span>
                <span>行业领域：{{ info.fieldName }}</span>
            </div>
            <div class="time">
                <span>{{ formatTime(info.createdAt, 'YYYY.MM.DD') }}</span>
            </div>
        </div>
        <!-- 
        <div class="btn">
            <el-button size="small"> 查看详情 </el-button>
        </div> -->
    </router-link>
</template>

<script>
import comEvent from '../../mixins/comEvent';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        type() {
            return this.$route.query.type;
        },
        ownerName() {
            if (this.type === 'COMPANY') {
                return '所属地区';
            } else {
                return (this.type === 'PRODUCT' ? '产品' : '技术') + '所属人';
            }
        }
    },
    mixins: [comEvent]
};
</script>

<style lang="less" scoped>
.patent {
    padding: 10px 20px;
    .flex();

    .el-image {
        width: 140px;
        height: 140px;
        flex-shrink: 0;
    }
    position: relative;
    cursor: pointer;
    border: 1px solid transparent;
    border-bottom-color: @bg;

    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(81,81,81,0.1);
        // border: 1px solid @prim;
        border: 1px solid #01A041;
        .content {
            .title {
                color: #01A041;
            }
            .desc {
                color: #01A041;
            }
            .time {
                color: #01A041;
            }
        }
    }
    overflow: hidden;
}

.content {
    padding: 0 10px 0 16px;
    flex-grow: 1;
    height: 140px;
    .flex-col();
    overflow: hidden;
    .title {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        line-height: 20px;
        padding: 24px 0 8px 0;
        .ellipsis();
    }

    .desc {
        font-size: 13px;
        color: #939599;
        line-height: 18px;
        .ellipsis-line(2);
        flex-grow: 1;
        max-height: 36px;
    }
    .time {
        font-size: 14px;
        color: #939599;
        line-height: 20px;
        padding: 8px 0;

        .flex();
        span {
            &:nth-child(2) {
                flex-grow: 1;
                margin: 0 30px;
            }
        }
    }
}
</style>
